import React from "react";

const Notifications = (props) => {
  return (
    <div>
      <div className="text-3xl font-bold">Notifications</div>
    </div>
  );
};

export default Notifications;
